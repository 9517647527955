import config from "../config";

const getMybeListCategories = async () => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: false,
    endpoint: `/api/type/getMybeListType`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getEntityByType = async (
  typeId,
  searchTerm,
  pagination = { page: 1, perPage: 50 }
) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: false,
    endpoint: `/api/entity/indexByType?type_id=${typeId}${
      searchTerm ? `&search=${searchTerm === "null" ? "" : searchTerm}` : ""
    }${
      pagination ? `&page=${pagination.page}&perPage=${pagination.perPage}` : ""
    }`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const queryEntityWithinType = async (obj) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: obj,
    endpoint: `/api/entity/queryWithinType`,
    method: "POST",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getEntity = async (id, typeId) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: false,
    endpoint: `/api/entity/${id}?type_id=${typeId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const putEntity = async (id, obj) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: obj,
    endpoint: `/api/entity/${id}`,
    method: "PUT",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const postEntity = async (obj) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: obj,
    endpoint: `/api/entity`,
    method: "POST",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteEntity = async (id, typeId) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: false,
    endpoint: `/api/entity/${id}?type_id=${typeId}`,
    method: "DELETE",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const validateEntity = async (obj) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: obj,
    endpoint: `/api/entity/verify`,
    method: "POST",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreEntity = async (id, typeId) => {
  const options = {
    base: `https://entity-service`,
    params: false,
    payload: false,
    endpoint: `/api/entity/${id}/restore?type_id=${typeId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getMybeListCategories,
  getEntityByType,
  queryEntityWithinType,
  getEntity,
  postEntity,
  deleteEntity,
  validateEntity,
  putEntity,
  restoreEntity,
};